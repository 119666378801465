
/* SnackForm CSS below */

.SnackForm{
    background: red;
    height: 200px;
    border: 2px solid black;
    padding: 0 1em 1em 10;
    display: flex;
    flex-direction: column;
    width: fit-content;
    border-radius: 10px;
    padding: .2em 1em 1em 1em;
}
.SnackForm-game-number{
    border: 1px solid black;
    margin: 0;
    border-radius: 4px;
    margin-left: 0;
    width: fit-content;
    background-color: lime;
}
.SnackForm-title{
    color: white;
    text-align: center;
    margin-top: 5px;
    text-decoration: underline;
}
.SnackForm-input{
    position: relative
}



/* Next practice comment on Main page */
/* Parents snack schedule in Calender - Form */
