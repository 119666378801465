.Footer{
    font-family:Verdana, Geneva, Tahoma, sans-serif ;
    background-color: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1em;
}
.copyright-logo{
    height: 20px;
    width: 20px;
}
.company-name{
    font-family:Verdana, Geneva, Tahoma, sans-serif ;
}