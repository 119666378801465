.player-card{
    position: relative;
    padding: 1em;
    margin: 1em;
    box-shadow: 0 2px 5px ;
}
.player-card > button{
    padding: .25em;
    border-radius: 5px;
    margin-right: 15px;
}
.gender-icon-male{
    position: absolute;
    right: 1em;
    top: 1em;
}
.gender-icon-female{
    position: absolute;
    right: 1em;
    top: 1em;
}
.batting-order-placement{
    position: absolute;
    width: 40px;
    height: 30px;
    top: 3em;
    right: .9em;
    text-align: center;
    background-color: lightblue;
    border: 1px solid black;
    padding-top: .8em;
}