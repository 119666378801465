.game-card{
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 1.5em;
    padding: 1em;
    width: 40%;
    box-shadow: 0 2px 5px 5px;
    text-align: center;
}
.game-card-date{
    margin-bottom: 5px;
    font-size: 14px;
}
.game-card-image{
    margin: auto;
    margin-top: 10px;
    width: 30%
}
.game-card-links{
    display: flex;
    justify-content: center;
    gap: 2em;
}
.next-game-button{
    padding: .3em;
    width: 30%;
    margin: auto;
    font-size: 20px;
    border-radius: 15px;
    font-family: 'Bebas Neue', cursive;
    color: red;
}

/******************MOBILE / TABLET  VIEW BELOW *************/
@media only screen and (max-width: 1244px) {
    .game-card{
        width: 80%;
        margin: auto;
        margin-top: 1.5em;
        margin-bottom: 1em;
        padding: 1em;
        box-shadow: 0 2px 5px 5px;
        text-align: center;
    }
    .game-card-date{
        font-size: 14px;
    }
    .game-card-image{
        width: 100%
    }
    .game-card-links{
        display: flex;
        justify-content: center;
        gap: 2em;
    }
    .next-game-button{
        padding: .3em;
        font-size: 20px;
        border-radius: 15px;
        font-family: 'Bebas Neue', cursive;
        color: red;
        width: fit-content;
    }
}




