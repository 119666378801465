.game-card-container{
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 2px 5px 2px;
    margin: 1em;
    padding: 1em;
}
.game-card-title-label{
    text-align: center;
    text-decoration: underline;
    margin-bottom: 5px;
}
.game-card-title{
    text-align: center;
    margin-top: 0;
    grid-column: 1 / 2;
}
.game-card-description-label{
    text-align: center;
    text-decoration: underline;
    grid-column: 1 / 2;
    margin-bottom: 5px;
    margin-top: 5px;
}
.game-card-description{
    margin-top: 0;
    text-align: center;
    grid-column: 1 / 2;
}
.game-image{
    position: absolute;
    width: 100%;
    height: 55%;
    top: 1.2em;
    border-radius: 10px;
    grid-column: 2 / 3;
    border: 1px solid black;
}
.game-card-edit-button{
    grid-column: 1 / -1;
    display: inline;
}
.game-card-delete-button{
    grid-column: 1 / -1;
    display: inline;
}

/* editing game CSS below*/
.game-card-editing-container{
    display: flex;
    flex-direction: column;
    gap: .5em;
    box-shadow: 0 2px 5px 2px;
    padding: 1em;
    
}
.game-card-editing-container > input{
    height: 30px;
    width: 80%;
    margin: auto;
    border: 1px solid black;
    margin-top: 0;
}
.game-card-editing-container > label{
    font-weight: bold;
    width: 80%;
    margin: auto;
    margin-bottom: 0;
}
.save-button{
    width: 50%;
    margin: auto;
    margin-top: 1em;
}
.game-card-delete-button-editing{
    width: 50%;
    margin: auto;
}
