.game-details-container{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    text-align: center;
    margin-top: 1em;
}
hr{
    display: none
}
.game-details-picture{
    margin: auto;
    width: 30%;
    border-radius: 20px;
}
.game-details-address{
    font-size: 15px;
}
.game-details-title{
    grid-row: 1 / 3;
}
.game-details-date{
    font-size: 14px;
}
.batting-line-up-container{
    /* columns: 2; */
    box-shadow: 0 2px 5px 3px;
    gap: 1em;
    width: 30%;
    padding: 1em 0px 1em 8em;
    margin:auto;
    margin-bottom: 1em;
    list-style: none;
}

.label-for-line-up{
    text-align: center;
    text-decoration: underline;
}
.link-container{
    text-align: center;
    margin-bottom: 1.5em;
    padding-top: 1em;
}
.back-to-home-link{
    border: 2px solid black;
    padding: .25em;
    background-color: red;
    border-radius: 10px;
    text-decoration: none;
    color: black;
    font-weight: 900;
}
.game-location{
    font-size: 20px;
}
.to-be-determined-box{
    box-shadow: 0 2px 5px;
    width: 40%;
    margin: auto;
    padding: 1em;
    text-align: center;
}


/******************MOBILE / TABLET  VIEW BELOW *************/
@media only screen and (max-width: 1244px) {
    .game-details-container{
        width: 100%;
        display: inline-block;
        text-align: center;
    }
    hr{
        display: block;
    }
    .game-details-picture{
        width: 100%;
        margin-bottom: 1em;
    }
    .game-details-date{
        font-size: 14px;
    }
    .batting-line-up-container{
        /* columns: 2; */
        box-shadow: 0 2px 5px 3px;
        gap: 1em;
        width: 70%;
        padding-left: 2em;
        margin:auto;
        margin-bottom: 1em;
        list-style: none;
    }
    
    .label-for-line-up{
        text-align: center;
        text-decoration: underline;
    }
    .link-container{
        text-align: center;
        margin-bottom: 1.5em;
        padding-top: 1em;
    }
    .back-to-home-link{
        border: 2px solid black;
        padding: .25em;
        background-color: red;
        border-radius: 10px;
        text-decoration: none;
        color: black;
        font-weight: 900;
    }
}