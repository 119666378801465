/**************Desktop Version Below********** */
header{
    background-color: red;
    background: linear-gradient(180deg, red,red,red,red,red,red, grey);
    padding: 2em;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.Header-title{
    grid-column: 2 / 3;
    color: whitesmoke;
    margin: auto;
    font-family: 'Bebas Neue', cursive;
    font-size: 40px;
}
.Header-logo{
    display: none;
    position: relative;
    text-align: left;
}
.desktopLinkContainer{
   grid-column: 1 / 2;
   grid-row: 1 / 2;
   display: flex;
   align-items: center;
   justify-content: space-around;
}
.desktopNavLink{
    grid-column: 3 / 4;
    text-align: center;
    color: black;
    padding: .5em;
    text-decoration: none;
    font-size: 25px;
    font-family: 'Bebas Neue', cursive;
}
.desktopNavLink:hover{
   text-decoration: underline;
   cursor: pointer;
}
.dropDownMenu{
    position: absolute;
    top: 5em;
    display: flex;
    flex-direction: column;
    gap: .75em;
    padding: .5em;
    border: 3px solid black;
    box-shadow: 0 2px 5px 0;
    width: 25%;
    background: linear-gradient(135deg, grey, white);
}
.menuLink{
    outline: 2px solid red;
    line-height: 30px;
    text-indent: 7px;
    border-radius: 9px;
    text-decoration: none;
    color: black;
    font-weight: 550;
}

.desktop-coach-login-link{
    grid-column: 3 / 4;
    font-family: sans-serif;
    font-weight: bolder;
    color: black;
    margin-left: auto;
    text-decoration: underline;
    background: none;
    border: none;
}
.desktop-coach-login-link:hover{
    transform: scale(1.5);
    cursor: pointer;
}
.menuLink:hover{
    background-color: rgb(212, 110, 110);
    transform: scale(1.1);
}
.coach-login-form{
    position: absolute;
    top: 15%;
    right: 1%;
}
.form-container{
    padding: .5em;
    line-height: 2;
    background-color: lightslategray;
    border-radius: 10px;
}
/******************MOBILE / TABLET  VIEW BELOW *************/
@media only screen and (max-width: 1244px){
    header{
        background-color: red;
        background: linear-gradient(180deg, red,red,red,red,red,red, grey);
        padding: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .Header-title{
        color: whitesmoke;
        text-align: center;
        font-size: 30px;
        font-family: 'Bebas Neue', cursive;
    }
    .Header-logo{
        display:block;
        position: relative;
        text-align: left;
    }
    .desktopLinkContainer{
        display: none;
    }
    .desktopNavLink{
        display: none;
    }
    .dropDownMenu{
        position: absolute;
        top: 5em;
        display: flex;
        flex-direction: column;
        gap: .75em;
        padding: .5em;
        border: 3px solid black;
        box-shadow: 0 2px 5px 0;
        width: 50%;
        background: linear-gradient(135deg, grey, white);
    }
    .menuLink{
        outline: 2px solid red;
        line-height: 30px;
        text-indent: 7px;
        border-radius: 9px;
        text-decoration: none;
        color: black;
        font-weight: 550;
    }
    
    .coach-login-link{
        font-family: sans-serif;
        color: blue;
        text-decoration: underline;
        background: none;
        border: none;
        width: fit-content;
    }
    .coach-login-link:hover{
        transform: scale(1.1);
        cursor: pointer;
    }
    .desktop-coach-login-link{
       display: none;
    }
    .menuLink:hover{
        background-color: rgb(212, 110, 110);
        transform: scale(1.05);
    }
    
    .coach-login-form{
        position: absolute;
        top: 15em;
        left: .6em;
    }
    .form-container{
        font-family: sans-serif;
        width: fit-content;
        border: 1px solid black;
        padding: .5em;
        line-height: 2;
        background-color: lightslategray;
        border-radius: 10px;
    }
    .form-container > input{
        font-weight: bolder;
        letter-spacing: 2px;
        height: 20px;
    }
    .coach-login-button{
        width: 65px;
        border-radius: 5px;
        padding: .25em;
        margin-left: 3em;
    }
    .coach-login-button:hover{
       transform: scale(1.1);
    }
    .forgot-username-password{
        color: blue;
        cursor: pointer;
        text-decoration: underline;
    }
}